@charset "UTF-8";
.card,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
.icon,
.icon path,
.icon polygon,
.icon use {
    fill: currentColor;
}

.menu-spacing {
    display: inline;
    padding-right: 30px;
}
.default-white-text {
    color: white;
}

.nav-desktop .nav-parent > a:after,
.nav-desktop > ul > li {
    position: relative;
    display: inline-block;
}
.carousel,
.event-description,
.event-name,
.nav-desktop > ul {
    white-space: nowrap;
}
.event-info,
.site-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
}
.element-form label,
.monthly-day-title-wrap div,
.monthly-header-title,
.photo-gallery-caption {
    text-transform: uppercase;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
summary,
time,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
    content: "";
    content: none;
}
a:link,
a:visited {
    text-decoration: none;
    color: inherit;
}
table {
    border-spacing: 0;
}
html {
    height: 100%;
    box-sizing: border-box;
}
body,
html {
    min-height: 100%;
    width: 100%;
    position: relative;
    font-family: sans-serif;
    font-size: 0.95em;
    line-height: 1.4em;
    -webkit-font-smoothing: antialiased;
}
body {
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
}
*,
:after,
:before {
    box-sizing: inherit;
}
.centering {
    max-width: 1000px;
    margin: 0 auto;
}
.card {
    background: #eee;
    padding: calc(15px * 2);
    border-radius: 3px;
}
.title {
    text-align: center;
    font-weight: 700;
    font-size: 1.4em;
    line-height: 1.2em;
    padding: 1em;
}
.pagination {
    text-align: center;
    font-weight: 700;
    font-size: 0.9em;
    padding: 0.5em;
}
.pagination a:link,
.pagination a:visited {
    display: inline-block;
    padding: 0 0.5em;
}
.pagination a:hover {
    color: #0a68b6;
}
.button,
.button-alt,
a.button,
a.button-alt {
    padding: 0.75em 3em;
    border-radius: 3em;
    font-size: inherit;
    outline: 0;
    border: 0;
    background: #0a68b6;
    color: #fff !important;
    display: inline-block;
}
.button-alt:hover,
.button:hover,
a.button:hover {
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    cursor: pointer;
}
.button-alt:active,
.button:active,
a.button:active {
    -webkit-filter: brightness(1.3);
    filter: brightness(1.3);
}
.button-alt,
a.button-alt {
    background: #444;
}
.button-small,
.button-small-alt,
a.button-small,
a.button-small-alt {
    display: inline-block;
    padding: 0.25em 1em;
    color: #fff !important;
    font-size: 0.75em;
    text-transform: uppercase;
    outline: 0;
    border: 0;
    background: #0a68b6;
    font-weight: 700;
    border-radius: 3em;
}
.button-small-alt:hover,
.button-small:hover,
a.button-small:hover {
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
    cursor: pointer;
}
.button-small-alt:active,
.button-small:active,
a.button-small:active {
    -webkit-filter: brightness(1.3);
    filter: brightness(1.3);
}
.button-small-alt,
a.button-small-alt {
    background: #444;
}
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
}
.grid,
[class*="grid-"] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.grid:after,
[class*="grid-"]:after {
    content: "";
    display: block;
    clear: both;
}
.align-tc,
.align-tl {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: start;
}
.grid-pad > [class*="col-"] {
    padding: 15px;
}
.col-1 {
    width: 8.33%;
}
.col-2 {
    width: 16.66%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.33%;
}
.col-5 {
    width: 41.66%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.33%;
}
.col-8 {
    width: 66.66%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.33%;
}
.col-11 {
    width: 91.66%;
}
.col-12 {
    width: 100%;
}
@media screen and (max-width: 960px) {
    .grid-stepped-720 .col-1 {
        width: 16.66%;
    }
    .grid-stepped-720 .col-2 {
        width: 25%;
    }
    .grid-stepped-720 .col-3 {
        width: 33.33%;
    }
    .grid-stepped-720 .col-4 {
        width: 41.66%;
    }
    .grid-stepped-720 .col-5 {
        width: 50%;
    }
    .grid-stepped-720 .col-6 {
        width: 58.33%;
    }
    .grid-stepped-720 .col-7 {
        width: 66.66%;
    }
    .grid-stepped-720 .col-8 {
        width: 75%;
    }
    .grid-stepped-720 .col-9 {
        width: 83.33%;
    }
    .grid-stepped-720 .col-10 {
        width: 91.66%;
    }
    .grid-960 [class*="col-"] {
        width: 100%;
    }
}
@media screen and (max-width: 720px) {
    .grid-stepped-512 .col-1 {
        width: 16.66%;
    }
    .grid-stepped-512 .col-2 {
        width: 25%;
    }
    .grid-stepped-512 .col-3 {
        width: 33.33%;
    }
    .grid-stepped-512 .col-4 {
        width: 41.66%;
    }
    .grid-stepped-512 .col-5 {
        width: 50%;
    }
    .grid-stepped-512 .col-6 {
        width: 58.33%;
    }
    .grid-stepped-512 .col-7 {
        width: 66.66%;
    }
    .grid-stepped-512 .col-8 {
        width: 75%;
    }
    .grid-stepped-512 .col-9 {
        width: 83.33%;
    }
    .grid-stepped-512 .col-10 {
        width: 91.66%;
    }
    .grid-720 [class*="col-"] {
        width: 100%;
    }
}
@media screen and (max-width: 512px) {
    .grid-512 [class*="col-"] {
        width: 100%;
    }
}
.align-tl {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.align-tc {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.align-cl,
.align-tr {
    display: -webkit-box;
    display: -ms-flexbox;
}
.align-tr {
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.align-c,
.align-cl {
    -webkit-box-align: center;
}
.align-cl {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.align-c,
.align-cr {
    display: -webkit-box;
    display: -ms-flexbox;
}
.align-c {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.align-cr {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.align-bc,
.align-bl {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: end;
}
.align-bl {
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.align-bc {
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.align-br,
.site-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
}
.align-br {
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.nav-desktop {
    max-width: 100%;
}
.nav-desktop #currentpage {
    color: #ff7545;
}
.nav-desktop > ul {
    display: inline-block;
}
.nav-desktop .icon {
    width: 1em;
    height: 1em;
    color: rgba(0, 0, 0, 0.5);
}
.nav-desktop .nav-parent > a:after {
    content: "";
    border: 0.3em solid transparent;
    border-left: 0.3em solid #222;
    left: 0.2em;
    text-align: center;
    font-weight: 700;
    vertical-align: middle;
    line-height: 1em;
    font-size: 1.3em;
    font-family: serif;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nav-desktop .nav-parent:hover > a:after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 0.15em;
    border-left: 0.3em solid #fff;
}
.nav-desktop li:hover > ul {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}
.nav-desktop > ul > li > a:link,
.nav-desktop > ul > li > a:visited {
    display: block;
    padding: 1.5em 1em;
    color: #fff;
}
.nav-desktop > ul > li:hover {
    background: rgba(0, 0, 0, 0.5);
}
.nav-desktop > ul > li:hover .icon,
.nav-desktop > ul > li:hover > a:link,
.nav-desktop > ul > li:hover > a:visited {
    color: #fff;
}
.nav-desktop > ul > li ul {
    position: absolute;
    top: 100%;
    left: 0;
    background: #ccc;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    min-width: 100%;
    text-align: left;
    z-index: 100;
}
.nav-desktop > ul > li ul li {
    min-width: 100%;
    display: block;
    position: relative;
}
.nav-desktop > ul > li ul a:link,
.nav-desktop > ul > li ul a:visited {
    display: block;
    padding: 1em;
}
.nav-desktop > ul > li ul li:hover {
    background: rgba(0, 0, 0, 0.5);
}
.nav-desktop > ul > li ul a:hover,
.nav-desktop > ul > li ul li:hover > a {
    color: #fff;
}
.nav-desktop > ul > li ul.menu-reposition {
    left: auto;
    right: 0;
}
.nav-desktop > ul > li > ul > li ul {
    position: absolute;
    top: 0;
    left: 100%;
    background: #ccc;
    min-width: 150px;
}
.nav-desktop > ul > li > ul > li ul li {
    position: relative;
}
.nav-desktop > ul > li > ul > li ul.menu-reposition {
    left: auto;
    right: 100%;
}
#hamburger {
    display: none;
    padding: 1.5em;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
}
.nav-mobile,
.nav-mobile > ul > li ul {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: -100vw;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    overflow: auto;
}
.nav-mobile {
    background: #0a68b6;
    transition: left 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nav-mobile ul {
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.accordion-topic,
.carousel li {
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.nav-mobile a:link,
.nav-mobile a:visited {
    padding: 1em;
    display: block;
    font-size: 1.2em;
    color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.nav-mobile a.mobile-close,
.nav-mobile a.mobile-dropdown-back {
    background: rgba(0, 0, 0, 0.6);
    font-size: 0.8em;
    font-weight: 700;
    text-align: right;
    padding-right: 1.2em;
}
.contact-bar,
.nav-desktop,
footer {
    text-align: center;
}
.nav-mobile .mobile-dropdown-title {
    color: #fff;
    font-size: 2em;
    line-height: 2.4em;
    padding: 1rem;
    opacity: 0.6;
    font-weight: 100;
}
.nav-mobile > ul > li ul {
    background: #0a68b6;
}
.nav-mobile .nav-parent > a:before {
    content: "";
    border: 2px solid #fff;
    border-right: 0 solid transparent;
    border-bottom: 0 solid transparent;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: center;
    transform-origin: center;
    width: 0.5em;
    height: 0.5em;
    display: inline-block;
    vertical-align: baseline;
    position: relative;
    top: -0.05em;
    margin-right: 10px;
}
body {
    background: #192133;
}
.site-wrap {
    max-width: 1400px;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
}
header {
    background: url(./images/spine.png) right no-repeat, linear-gradient(to right, #000612, #000f2a, #000f2a);
    height: 540px;
    position: relative;
}
#swoop {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
#logo {
    width: 50%;
    height: 70%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
@media screen and (max-width: 1000px) {
    #logo img,
    header {
        height: auto;
    }
    #logo {
        background: rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 100%;
        padding: 75px 10px;
    }
    #logo img {
        max-width: 90%;
    }
}
.nav-desktop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
}
#content-wrap {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    background: #fff;
}
#content-centering {
    max-width: 1000px;
    margin: 0 auto;
    padding: 15px;
}
.contact-bar {
    background: url(./images/foot.jpg);
    background-size: cover;
    color: #fff;
}
.contact-bar b {
    text-shadow: none;
    color: #531a0f;
}
.contact-bar .col-4 {
    padding: 50px 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    font-weight: 700;
    text-shadow: 0 2px 5px #b1633f;
}
.contact-bar .col-4:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: -12px;
    left: 50%;
    margin-left: -10px;
    background: #fff;
}
footer {
    background: #000f27;
    border-top: 4px solid #193e76;
    padding: 2em;
    color: #fff;
}
.accordion-result h1,
.accordion-result h2,
.accordion-result h3,
.accordion-result h4,
.accordion-result h5,
.accordion-result h6,
.element-featured-text .text h1,
.element-featured-text .text h2,
.element-featured-text .text h3,
.element-featured-text .text h4,
.element-featured-text .text h5,
.element-featured-text .text h6,
.element-text h1,
.element-text h2,
.element-text h3,
.element-text h4,
.element-text h5,
.element-text h6,
.review h1,
.review h2,
.review h3,
.review h4,
.review h5,
.review h6,
.text-formatting h1,
.text-formatting h2,
.text-formatting h3,
.text-formatting h4,
.text-formatting h5,
.text-formatting h6 {
    line-height: 1.2em;
    margin: 1em 0;
}
.accordion-result h1,
.element-featured-text .text h1,
.element-text h1,
.review h1,
.text-formatting h1 {
    font-size: 2em;
    color: #ea8c4c;
}
.accordion-result h2,
.element-featured-text .text h2,
.element-text h2,
.review h2,
.text-formatting h2 {
    font-size: 1.5em;
}
.accordion-result h3,
.element-featured-text .text h3,
.element-text h3,
.review h3,
.text-formatting h3 {
    font-size: 1.2em;
}
.accordion-result h4,
.element-featured-text .text h4,
.element-text h4,
.review h4,
.text-formatting h4 {
    font-weight: 700;
}
.accordion-result h5,
.element-featured-text .text h5,
.element-text h5,
.review h5,
.text-formatting h5 {
    font-size: 0.8em;
    font-weight: 700;
}
.accordion-result h6,
.element-featured-text .text h6,
.element-text h6,
.review h6,
.text-formatting h6 {
    font-size: 0.65em;
    font-weight: 700;
}
.accordion-result blockquote,
.element-featured-text .text blockquote,
.element-text blockquote,
.review blockquote,
.text-formatting blockquote {
    background: rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin: 15px 0;
    font-size: 1.3em;
}
.accordion-result a:link,
.accordion-result a:visited,
.element-featured-text .text a:link,
.element-featured-text .text a:visited,
.element-text a:link,
.element-text a:visited,
.review a:link,
.review a:visited,
.text-formatting a:link,
.text-formatting a:visited {
    color: #0a68b6;
}
.accordion-result a:active,
.accordion-result a:hover,
.element-featured-text .text a:active,
.element-featured-text .text a:hover,
.element-text a:active,
.element-text a:hover,
.review a:active,
.review a:hover,
.text-formatting a:active,
.text-formatting a:hover {
    text-decoration: underline;
}
.accordion-result ol,
.accordion-result ul,
.element-featured-text .text ol,
.element-featured-text .text ul,
.element-text ol,
.element-text ul,
.review ol,
.review ul,
.text-formatting ol,
.text-formatting ul {
    overflow: hidden;
    padding: 0.5em 0.5em 0.5em 2em;
}
.accordion-result ul li,
.element-featured-text .text ul li,
.element-text ul li,
.review ul li,
.text-formatting ul li {
    list-style: disc;
}
.accordion-result ol li,
.element-featured-text .text ol li,
.element-text ol li,
.review ol li,
.text-formatting ol li {
    list-style: decimal;
}
.accordion-result table,
.element-featured-text .text table,
.element-text table,
.review table,
.text-formatting table {
    border-collapse: collapse;
}
.accordion-result thead th,
.element-featured-text .text thead th,
.element-text thead th,
.review thead th,
.text-formatting thead th {
    padding: 1em;
    text-align: left;
    border: 1px solid #fff;
    background: #ccc;
}
.accordion-result td,
.element-featured-text .text td,
.element-text td,
.review td,
.text-formatting td {
    padding: 1em;
    border: 1px solid #fff;
    background: #e3e3e3;
}
.accordion-result tr:nth-child(2n) td,
.element-featured-text .text tr:nth-child(2n) td,
.element-text tr:nth-child(2n) td,
.review tr:nth-child(2n) td,
.text-formatting tr:nth-child(2n) td {
    background: #efefef;
}
.accordion-result .rt-wrap,
.element-featured-text .text .rt-wrap,
.element-text .rt-wrap,
.review .rt-wrap,
.text-formatting .rt-wrap {
    max-width: 100%;
}
.accordion-result .mobile-table,
.element-featured-text .text .mobile-table,
.element-text .mobile-table,
.review .mobile-table,
.text-formatting .mobile-table {
    border: 1px solid #ccc;
    max-width: 100%;
}
.accordion-result .mobile-table thead,
.element-featured-text .text .mobile-table thead,
.element-text .mobile-table thead,
.review .mobile-table thead,
.text-formatting .mobile-table thead {
    display: none;
}
.accordion-result .mobile-table td,
.accordion-result .mobile-table tr,
.element-featured-text .text .mobile-table td,
.element-featured-text .text .mobile-table tr,
.element-text .mobile-table td,
.element-text .mobile-table tr,
.review .mobile-table td,
.review .mobile-table tr,
.text-formatting .mobile-table td,
.text-formatting .mobile-table tr {
    display: block;
    box-sizing: border-box;
    width: 100% !important;
    max-width: 100%;
    border: none;
    overflow: hidden;
}
.accordion-result .mobile-table td[data-title]:before,
.element-featured-text .text .mobile-table td[data-title]:before,
.element-text .mobile-table td[data-title]:before,
.review .mobile-table td[data-title]:before,
.text-formatting .mobile-table td[data-title]:before {
    content: attr(data-title) " ";
    font-weight: 700;
}
.accordion-result .hidden-table,
.element-featured-text .text .hidden-table,
.element-text .hidden-table,
.review .hidden-table,
.text-formatting .hidden-table {
    border: none;
    background: 0 0;
}
.accordion-result .hidden-table td,
.accordion-result .hidden-table th,
.accordion-result .hidden-table tr,
.accordion-result .hidden-table tr:nth-child(2n),
.accordion-result .hidden-table tr:nth-child(2n) td,
.element-featured-text .text .hidden-table td,
.element-featured-text .text .hidden-table th,
.element-featured-text .text .hidden-table tr,
.element-featured-text .text .hidden-table tr:nth-child(2n),
.element-featured-text .text .hidden-table tr:nth-child(2n) td,
.element-text .hidden-table td,
.element-text .hidden-table th,
.element-text .hidden-table tr,
.element-text .hidden-table tr:nth-child(2n),
.element-text .hidden-table tr:nth-child(2n) td,
.review .hidden-table td,
.review .hidden-table th,
.review .hidden-table tr,
.review .hidden-table tr:nth-child(2n),
.review .hidden-table tr:nth-child(2n) td,
.text-formatting .hidden-table td,
.text-formatting .hidden-table th,
.text-formatting .hidden-table tr,
.text-formatting .hidden-table tr:nth-child(2n),
.text-formatting .hidden-table tr:nth-child(2n) td {
    color: inherit;
    border: none;
    background: 0 0;
}
.accordion-result .embed-float-right,
.element-featured-text .text .embed-float-right,
.element-text .embed-float-right,
.review .embed-float-right,
.text-formatting .embed-float-right {
    float: right;
    margin: 0 0 30px 30px;
    clear: both;
}
.accordion-result .embed-float-left,
.element-featured-text .text .embed-float-left,
.element-text .embed-float-left,
.review .embed-float-left,
.text-formatting .embed-float-left {
    float: left;
    margin: 0 30px 30px 0;
    clear: both;
}
.accordion-result .embed-float-center,
.element-featured-text .text .embed-float-center,
.element-text .embed-float-center,
.review .embed-float-center,
.text-formatting .embed-float-center {
    margin: 15px auto;
    clear: both;
}
.accordion-result .rwd-embed,
.element-featured-text .text .rwd-embed,
.element-text .rwd-embed,
.review .rwd-embed,
.text-formatting .rwd-embed {
    max-width: 100%;
    position: relative;
}
.accordion-result .rwd-embed:after,
.element-featured-text .text .rwd-embed:after,
.element-text .rwd-embed:after,
.review .rwd-embed:after,
.text-formatting .rwd-embed:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 0 0;
    z-index: 1;
}
.accordion-result .rwd-aspect,
.element-featured-text .text .rwd-aspect,
.element-text .rwd-aspect,
.review .rwd-aspect,
.text-formatting .rwd-aspect {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.accordion-result .rwd-aspect embed,
.accordion-result .rwd-aspect iframe,
.accordion-result .rwd-aspect object,
.element-featured-text .text .rwd-aspect embed,
.element-featured-text .text .rwd-aspect iframe,
.element-featured-text .text .rwd-aspect object,
.element-text .rwd-aspect embed,
.element-text .rwd-aspect iframe,
.element-text .rwd-aspect object,
.review .rwd-aspect embed,
.review .rwd-aspect iframe,
.review .rwd-aspect object,
.text-formatting .rwd-aspect embed,
.text-formatting .rwd-aspect iframe,
.text-formatting .rwd-aspect object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
.accordion-result .theme-img,
.element-featured-text .text .theme-img,
.element-text .theme-img,
.review .theme-img,
.text-formatting .theme-img {
    border-radius: 6px;
    box-shadow: 0 0 5px #555;
}
.accordion-result .float-center,
.accordion-result .float-left,
.accordion-result .float-normal,
.accordion-result .float-right,
.element-featured-text .text .float-center,
.element-featured-text .text .float-left,
.element-featured-text .text .float-normal,
.element-featured-text .text .float-right,
.element-text .float-center,
.element-text .float-left,
.element-text .float-normal,
.element-text .float-right,
.review .float-center,
.review .float-left,
.review .float-normal,
.review .float-right,
.text-formatting .float-center,
.text-formatting .float-left,
.text-formatting .float-normal,
.text-formatting .float-right {
    max-width: 100%;
    height: auto;
    clear: both;
}
.accordion-result .float-right,
.element-featured-text .text .float-right,
.element-text .float-right,
.review .float-right,
.text-formatting .float-right {
    float: right;
    margin: 5px 0 5px 15px;
}
.accordion-result .float-left,
.element-featured-text .text .float-left,
.element-text .float-left,
.review .float-left,
.text-formatting .float-left {
    margin: 5px 15px 0 5px;
    float: left;
}
.accordion-result .float-center,
.element-featured-text .text .float-center,
.element-text .float-center,
.review .float-center,
.text-formatting .float-center {
    display: block;
    margin: 10px auto;
}
.accordion-result .float-normal,
.element-featured-text .text .float-normal,
.element-text .float-normal,
.review .float-normal,
.text-formatting .float-normal {
    display: inline;
}
.accordion-result .align-center,
.accordion-result .align-justify,
.accordion-result .align-left,
.accordion-result .align-right,
.element-featured-text .text .align-center,
.element-featured-text .text .align-justify,
.element-featured-text .text .align-left,
.element-featured-text .text .align-right,
.element-text .align-center,
.element-text .align-justify,
.element-text .align-left,
.element-text .align-right,
.review .align-center,
.review .align-justify,
.review .align-left,
.review .align-right,
.text-formatting .align-center,
.text-formatting .align-justify,
.text-formatting .align-left,
.text-formatting .align-right {
    display: block;
}
.accordion-result .align-right,
.element-featured-text .text .align-right,
.element-text .align-right,
.review .align-right,
.text-formatting .align-right {
    text-align: right;
}
.accordion-result .align-left,
.element-featured-text .text .align-left,
.element-text .align-left,
.review .align-left,
.text-formatting .align-left {
    text-align: left;
}
.accordion-result .align-center,
.element-featured-text .text .align-center,
.element-text .align-center,
.review .align-center,
.text-formatting .align-center {
    text-align: center;
}
.accordion-result .align-justify,
.element-featured-text .text .align-justify,
.element-text .align-justify,
.review .align-justify,
.text-formatting .align-justify {
    text-align: justify;
}
.accordion-result figure,
.element-featured-text .text figure,
.element-text figure,
.review figure,
.text-formatting figure {
    display: block;
    font-size: 1.4em;
    line-height: 1.4em;
    margin-bottom: 5px;
    background-color: #ccc;
    padding: 15px;
    border-radius: 3px;
}
.element-text {
    padding: 15px;
    color: #3d3e4c;
}
.element-text:after {
    content: "";
    display: block;
    clear: both;
}
.accordion-topic {
    padding: 1em 1em 1em 3em;
    background: rgba(0, 0, 0, 0.1);
    margin-bottom: 1px;
    cursor: pointer;
    font-weight: 700;
    position: relative;
}
.accordion-topic:before {
    content: "+";
    font-weight: 700;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2.5em;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.2em;
}
.accordion-topic:hover {
    background: rgba(0, 0, 0, 0.15);
}
.accordion-open,
.accordion-open:hover {
    background: #0a68b6;
    color: #fff;
}
.accordion-open:before,
.accordion-open:hover:before {
    color: #fff;
}
.accordion-open:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.accordion-result {
    padding: 1em 1em 1em 1.5em;
    background: rgba(0, 0, 0, 0.05);
    font-size: 0.9em;
    display: none;
}
.accordion-result:after {
    content: "";
    display: block;
    clear: both;
}
.carousel {
    overflow: hidden;
    font-size: 0;
}
.carousel li {
    display: inline-block;
    width: 25%;
    vertical-align: middle;
}
.carousel img {
    max-width: 100%;
    height: auto;
    padding: 10px;
}
.carousel:after {
    content: "";
    clear: both;
}
.carousel-buttons-wrap {
    text-align: center;
}
.carousel-next,
.carousel-prev {
    display: inline-block;
    padding: 0.5rem 2rem;
    background: #eee;
    border-radius: 20px 0 0 20px;
    text-align: center;
}
.carousel-next:before,
.carousel-prev:before {
    content: "";
    display: block;
    width: 0.6rem;
    height: 0.6rem;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-left: 0;
    border-bottom: 0;
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-133deg);
    transform: rotate(-133deg);
}
.carousel-next {
    border-radius: 0 20px 20px 0;
}
.carousel-next:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.carousel-next:hover,
.carousel-prev:hover {
    background: #0a68b6;
}
.carousel-next:hover:before,
.carousel-prev:hover:before {
    content: "";
    border: 2px solid #fff;
    border-left: 0;
    border-bottom: 0;
}
.monthly {
    background: #f3f3f5;
    color: #545454;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}
.monthly-header {
    position: relative;
    text-align: center;
    padding: 0.5em;
    background: #fff;
    height: 3em;
    box-sizing: border-box;
}
.monthly-header-title a:link,
.monthly-header-title a:visited {
    display: inline-block;
    border: 1px solid #ccc;
    color: #545454;
    text-decoration: none;
    height: 1.75em;
    line-height: 1.65em;
    padding: 0 0.65em;
    box-sizing: border-box;
    transition: background 0.1s;
}
.monthly-header-title a:first-of-type {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
}
.monthly-header-title a:last-of-type {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
}
.monthly-header-title a:hover {
    background: #8b8b8b;
    border: 1px solid #8b8b8b;
    color: #fff;
}
.monthly-header-title a:active {
    background: #222;
    border: 1px solid #222;
    transition: none;
}
.monthly-header-title-date,
.monthly-header-title-date:hover {
    background: #eee !important;
    border: 1px solid #ccc !important;
    color: #545454 !important;
    cursor: default;
}
.monthly-reset {
    border-left: 0 !important;
}
.monthly-reset::before {
    content: "\21BB";
    margin-right: 0.25em;
}
.monthly-cal {
    border-right: 0 !important;
}
.monthly-cal::before {
    content: "\2637";
    margin-right: 0.25em;
}
.monthly-next,
.monthly-prev {
    position: absolute;
    top: 0;
    width: 3em;
    height: 100%;
    opacity: 0.5;
}
.monthly-prev {
    left: 0;
}
.monthly-next {
    right: 0;
}
.monthly-next:hover,
.monthly-prev:hover {
    opacity: 1;
}
.monthly-next:after,
.monthly-prev:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: #222;
    width: 0.6em;
    height: 0.6em;
    margin: -0.4em 0 0 -0.4em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.monthly-prev:after {
    border-width: 0 0 0.2em 0.2em;
}
.monthly-next:after {
    border-width: 0.2em 0.2em 0 0;
}
.monthly-day-title-wrap {
    display: table;
    table-layout: fixed;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
}
.monthly-day-title-wrap div {
    width: 14.28% !important;
    display: table-cell;
    box-sizing: border-box;
    position: relative;
    text-align: center;
}
.monthly-day-wrap {
    display: table;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
}
.monthly-week {
    display: table-row;
    width: 100%;
}
.monthly-day,
.monthly-day-blank {
    width: 14.28% !important;
    display: table-cell;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    color: inherit;
    background: #fff;
    box-shadow: 0 0 0 1px #ebebeb;
    transition: 0.25s;
    padding: 0;
    text-decoration: none;
}
.monthly-day:before {
    content: "";
    display: block;
    padding-top: 100%;
    float: left;
}
.monthly-day-wrap > a:hover {
    background: #a1c2e3;
}
.monthly-day-blank {
    background: #f3f3f5;
}
.monthly-day-event > .monthly-day-number {
    position: absolute;
    line-height: 1em;
    top: 0.2em;
    left: 0.25em;
}
.monthly-day-pick > .monthly-indicator-wrap {
    margin: 0;
}
.monthly-day-pick > .monthly-day-number:after,
.monthly-day-pick > .monthly-day-number:before {
    content: "";
    display: block;
    padding-top: calc(50% - 0.8em);
    width: 0;
    height: 0;
}
.monthly-past-day:after {
    content: "";
    width: 150%;
    height: 2px;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 0;
}
.monthly-past-day:hover {
    background: #fff !important;
}
.monthly-today .monthly-day-number {
    color: #fff;
    background: #ea6565;
    border-radius: 0.75em;
    top: 0.08em;
    left: 0.05em;
    font-size: 0.9em;
    padding: 0;
    width: 1.25em;
    height: 1.25em;
    line-height: 1.25em;
    text-align: center;
}
.monthly-day-pick.monthly-today .monthly-day-number {
    padding: 0.15em;
    margin: calc(50% - 0.7em) auto auto;
    font-size: 1em;
}
.monthly-indicator-wrap {
    position: relative;
    text-align: center;
    line-height: 0;
    max-width: 1.5em;
    margin: 0 auto;
    padding-top: 1.2em;
}
.monthly-day .monthly-event-indicator {
    display: inline-block;
    margin: 0.05em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.25em;
    vertical-align: middle;
    background: #7ba7ce;
}
.monthly-event-list .listed-event,
.monthly-list-item:after {
    margin-bottom: 0.5em;
    padding: 0.4em 1em;
    display: block;
}
.monthly-day .monthly-event-indicator span {
    color: transparent;
}
.monthly-day .monthly-event-indicator:hover {
    cursor: pointer;
}
.monthly-event-list {
    background: rgba(233, 235, 236, 0.9);
    overflow: auto;
    position: absolute;
    top: 2.5em;
    width: 100%;
    height: calc(100% - 2.5em);
    display: none;
    transition: 0.25s;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.monthly-list-item {
    position: relative;
    padding: 0.5em 0.7em 0.25em 4em;
    display: none;
    border-top: 1px solid #d6d6d6;
    text-align: left;
}
.monthly-event-list .monthly-today .monthly-event-list-date {
    color: #ea6565;
}
.monthly-event-list .listed-event {
    color: #fff;
    border-radius: 0.2em;
}
.monthly-list-item a:link,
.monthly-list-item a:visited {
    text-decoration: none;
}
.item-has-event {
    display: block;
}
.item-has-event:after {
    display: none !important;
}
.monthly-event-list-date {
    width: 4em;
    position: absolute;
    left: 0;
    top: 1.2em;
    text-align: center;
    font-weight: 700;
    line-height: 1.2em;
}
.monthly-list-time-end,
.monthly-list-time-start {
    font-size: 0.8em;
    display: inline-block;
}
.monthly-list-time-end:not(:empty):before {
    content: "\2013";
    padding: 0 2px;
}
.monthly-event-list::-webkit-scrollbar {
    width: 0.75em;
}
.monthly-event-list::-webkit-scrollbar-track {
    background: 0 0;
}
.monthly-event-list::-webkit-scrollbar-thumb {
    background: #ccc;
    border: 1px solid #e9ebec;
    border-radius: 0.5em;
}
.monthly-event-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.monthly-reset:after {
    content: "Today";
}
.monthly-cal:after {
    content: "Month";
}
.monthly-list-item:after {
    content: "No Events";
}
.monthly-locale-fr .monthly-reset:after {
    content: "aujourd'hui";
}
.monthly-locale-fr .monthly-cal:after {
    content: "mois";
}
.monthly-locale-fr .monthly-list-item:after {
    content: "aucun événement";
}
@media (min-width: 25em) {
    .monthly-day-event {
        padding-top: 1.3em;
    }
    .monthly-day-event > .monthly-indicator-wrap {
        width: auto;
        max-width: none;
    }
    .monthly-indicator-wrap {
        padding: 0;
    }
    .monthly-day:before {
        padding-top: calc(100% - 1.2em);
    }
    .monthly-day .monthly-event-indicator {
        display: block;
        margin: 0 0 1px;
        width: auto;
        height: 1.5em;
        line-height: 1.2em;
        padding: 0.125em 0 0.1em 0.125em;
        border-radius: 0;
        overflow: hidden;
        background-color: #333;
        color: #333;
        text-decoration: none;
        white-space: nowrap;
        box-sizing: border-box;
    }
    .monthly-day .monthly-event-indicator.monthly-event-continued {
        box-shadow: -1px 0 0 0;
    }
    .monthly-day .monthly-event-indicator span {
        display: block;
        width: auto;
        margin: 0;
        color: #fff;
        padding: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.document-list-header,
.document-list-search-wrap {
    background: rgba(0, 0, 0, 0.05);
}
.document-list-title {
    font-weight: 700;
    font-size: 1.5em;
}
.document-list-search {
    border: 0;
    padding: 1em;
    outline: 0;
    border-radius: 2em;
}
.document-list {
    max-height: 50vh;
    overflow: auto;
}
.document-list .icon {
    float: right;
    width: 1.2em;
    height: 1.2em;
    opacity: 0.5;
}
.document-list a:link,
.document-list a:visited {
    display: block;
    padding: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
    background: rgba(0, 0, 0, 0.05);
    margin-top: 1px;
}
.document-list a:hover {
    background: #0a68b6;
    color: #fff;
}
.document-list a:hover .icon {
    opacity: 1;
}
.event {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1em;
}
.event-date {
    font-weight: 700;
    border: 2px solid #ccc;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    font-size: 0.9em;
    text-align: center;
    line-height: 1em;
    padding-top: 0.7em;
}
.event-date div:nth-child(2n) {
    font-size: 1.2rem;
}
.event .event-date:nth-child(2) {
    margin-left: 5px;
    position: relative;
}
.event .event-date:nth-child(2):before {
    content: "";
    display: block;
    height: 2px;
    width: 5px;
    position: absolute;
    top: 50%;
    left: -7px;
    background: #ccc;
}
.event-info,
.image-zoom-controls {
    display: -webkit-box;
    display: -ms-flexbox;
}
.event-info {
    padding-left: 1em;
    min-width: 0;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-direction: column;
    flex-direction: column;
}
.event-name {
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
}
.event-description {
    overflow: hidden;
    text-overflow: ellipsis;
}
.event:hover .event-date {
    border: 2px solid #0a68b6;
}
.event:hover .event-name {
    color: #0a68b6;
}
.event:hover .event-date:nth-child(2):before {
    background: #0a68b6;
}
.element-form {
    line-height: 1.5em;
}
.element-form .grid,
.element-form .grid-720 {
    background: #f3f3f3;
}
.element-form .legend {
    font-weight: 700;
    font-size: 1em;
    line-height: 1.2em;
    padding: 1em 0 0.5em;
    color: #373737;
}
.element-form label {
    display: block;
    font-size: 0.8em;
    font-weight: 700;
    line-height: 1.3em;
    color: #737373;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
.element-form textarea {
    padding: 0.6em 0;
    border: 0;
    background: 0 0;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    height: 3em;
    max-width: 400px;
    color: #555;
    outline: 0;
    border-bottom: 2px solid #ccc;
    box-shadow: inset 0 0 0 0 #0a68b6;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"])::-webkit-input-placeholder,
.element-form textarea::-webkit-input-placeholder {
    font-style: italic;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):-moz-placeholder,
.element-form textarea:-moz-placeholder {
    font-style: italic;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"])::-moz-placeholder,
.element-form textarea::-moz-placeholder {
    font-style: italic;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):-ms-input-placeholder,
.element-form textarea:-ms-input-placeholder {
    font-style: italic;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):hover,
.element-form textarea:hover {
    border-bottom: 2px solid #737373;
    background: rgba(0, 0, 0, 0.05);
    padding: 0.6em;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):focus,
.element-form textarea:focus {
    border-bottom: 2px solid #0a68b6;
    box-shadow: inset 0 -3em 0 0 #0a68b6;
    color: #fff;
    padding: 0.6em;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):focus + label,
.element-form textarea:focus + label {
    color: #0a68b6;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):focus::-webkit-input-placeholder {
    color: #fff;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):focus:-moz-placeholder {
    color: #fff;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):focus::-moz-placeholder {
    color: #fff;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):focus:-ms-input-placeholder {
    color: #fff;
}
.element-form textarea {
    height: 8em;
    padding: 0.6em;
    border: 2px solid #ccc;
}
.element-form textarea:hover {
    border: 2px solid #737373;
}
.element-form textarea:focus {
    border: 2px solid #0a68b6;
    background: #0a68b6;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):required,
.element-form textarea:required {
    border-bottom: 2px solid #e37070;
}
.element-form input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):required:valid,
.element-form textarea:required:valid {
    border-bottom: 2px solid #0a68b6;
}
.element-form input:not([type="submit"]):not([type="radio"]):required + label:after,
.element-form textarea:required + label:after {
    content: " *";
    color: #e37070;
}
.element-form input[type="radio"],
.element-form input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
}
.element-form input[type="radio"] + label:before {
    content: "";
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    background: 0 0;
    border: 1px solid #555;
    border-radius: 50%;
    margin: 0 0.5em 0 0.3em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.element-form input[type="radio"]:required + label {
    color: #e37070;
}
.element-form input[type="radio"]:required + label:before {
    border: 1px solid #e37070;
}
.element-form input[type="radio"]:required:valid + label {
    color: inherit;
}
.element-form input[type="radio"]:required:valid + label:before {
    border: 1px solid #0a68b6;
}
.element-form input[type="radio"]:required:valid:not(:checked) + label:before {
    border: 1px solid #555;
}
.element-form input[type="radio"] + label {
    color: #555;
    margin-top: 0.5em;
    cursor: pointer;
    border-radius: 2px;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.element-form input[type="radio"] + label:hover {
    background: rgba(0, 0, 0, 0.05);
}
.element-form input[type="radio"]:checked + label {
    color: #000;
}
.element-form input[type="radio"]:checked + label:before {
    box-shadow: inset 0 0 0 3px #f3f3f3;
    background: #0a68b6;
}
.element-form input[type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    background: 0 0;
    border: 1px solid #555;
    border-radius: 2px;
    margin: 0 0.5em 0 0.3em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.element-form input[type="checkbox"]:required + label {
    color: #e37070;
}
.element-form input[type="checkbox"]:required + label:before {
    border: 1px solid #e37070;
}
.element-form input[type="checkbox"]:required:valid + label {
    color: inherit;
}
.element-form input[type="checkbox"]:required:valid + label:before {
    border: 1px solid #0a68b6;
}
.element-form input[type="checkbox"] + label {
    color: #555;
    margin-top: 0.5em;
    cursor: pointer;
    border-radius: 2px;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.element-form input[type="checkbox"] + label:hover {
    background: rgba(0, 0, 0, 0.05);
}
.element-form input[type="checkbox"]:checked + label {
    color: #000;
}
.element-form input[type="checkbox"]:checked + label:before {
    box-shadow: inset 0 0 0 2px #f3f3f3;
    background: #0a68b6;
}
.element-form input[type="submit"] {
    display: block;
    width: 100%;
    margin-top: 1em;
}
.element-form .rating-widget {
    opacity: 0.7;
}
.element-form .rating-widget .star {
    float: left;
    padding: 0.1em;
}
.element-form .rating-widget svg {
    width: 18px;
    height: 18px;
}
.element-form .rating-widget .hovered {
    color: #0a68b6;
}
.element-form .rating-widget:after {
    content: "";
    display: block;
    clear: both;
}
.element-form .rating-widget:hover {
    opacity: 1;
}
.element-form .rating-result {
    display: none;
}
.required:after {
    content: " *";
    color: #e37070;
}
.form-success {
    background: #bbdabb;
    border: 1px solid green;
    color: #026702;
    padding: 15px;
    margin: 5px 0;
    border-radius: 4px;
}
.form-errors {
    background: #f2c6c6;
    border: 1px solid red;
    color: #d60606;
    padding: 15px;
    margin: 5px 0;
    border-radius: 4px;
}
.element-featured-text .text {
    font-size: 1.5em;
    background: #eee;
    color: rgba(0, 0, 0, 0.65);
    padding: 1.5em;
}
.photo-gallery-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 60%;
    display: block;
    border-radius: 4px;
    margin-bottom: 0.5em;
    cursor: zoom-in;
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.staff-img,
.star-rating {
    border-radius: 4px 4px 0 0;
}
.photo-gallery-image:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
}
.photo-gallery-caption {
    text-align: center;
    font-size: 0.9em;
}
.image-zoom-overlay {
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    visibility: none;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    z-index: 100;
}
.image-zoom {
    width: 100%;
    height: 100%;
    position: relative;
    transition: opacity 0.2s;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.image-zoom-visible {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}
.image-zoom-controls {
    display: flex;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 30vh;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}
.image-zoom-controls a:link,
.image-zoom-controls a:visited {
    color: #fff;
    font-size: 18px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
    padding: 3vh;
}
.contain .image-zoom {
    background-size: contain;
}
.element-hero {
    min-height: 50vh;
    background: #222;
    position: relative;
    padding: 0 !important;
    overflow: hidden;
}
.hero-img {
    position: absolute;
    width: 110%;
    height: 110%;
    left: -5%;
    top: 0;
    background-size: cover;
    background-position: center;
}
.hero-text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    font-size: 50px;
    font-size: calc(24px + (50 - 24) * (100vw - 400px) / (1000 - 400));
    line-height: 1.3em;
    color: #fff;
    padding: 15px;
    background: rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 1000px) {
    .hero-text {
        font-size: 50px;
    }
}
.element-reviews {
    padding: 15px 0 !important;
}
.star-rating {
    display: block;
    text-align: center;
    background: #0a68b6;
    color: #fff;
    margin: calc(15px * -2);
    margin-bottom: 0;
    padding: 15px;
}
.review-star-empty {
    opacity: 0.3;
}
.staff-img {
    margin: calc(15px * -2);
    margin-bottom: 15px;
    padding-top: 150%;
    background-size: cover;
    background-position: center;
}
.element-staff,
.element-video-gallery {
    padding: 15px 0 !important;
}
.element-staff .card {
    transition: 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.element-staff .card:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background: #0a68b6;
    color: #fff;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
.element-video-gallery .rwd-embed {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;
}
.element-tabbed .tabs {
    border-bottom: 2px solid #0a68b6;
}
.element-tabbed .tabs a:link,
.element-tabbed .tabs a:visited {
    display: inline-block;
    background: #eee;
    padding: 0.75em 1em;
    float: left;
    margin-right: 1px;
}
.element-tabbed .tabs a:hover {
    background: #ccc;
}
.element-tabbed .tabs a:link:first-of-type {
    border-radius: 6px 0 0;
}
.element-tabbed .tabs a:link:last-of-type {
    border-radius: 0 6px 0 0;
}
.element-tabbed .tabs:after {
    content: "";
    display: block;
    clear: both;
}
.element-tabbed .tabs a.current-tab {
    background: #0a68b6;
    color: #fff;
}
.element-tabbed .tab-content {
    background: #eee;
    padding: 1em;
    display: none;
}
.smoothslides,
.smoothslides-on {
    position: relative;
    font-size: 0;
    line-height: 0;
    min-height: 40px;
    border-radius: 6px;
}
.smoothslides {
    background: rgba(255, 255, 255, 0.5);
}
.smoothslides img {
    display: none;
}
@keyframes throb {
    0%,
    100% {
        opacity: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(0.2);
        transform: scale(0.2);
    }
}
@-webkit-keyframes throb {
    0%,
    100% {
        opacity: 0;
        -webkittransform: scale(1);
    }
    50% {
        opacity: 1;
        -webkittransform: scale(0.2);
    }
}
.smoothslides:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -4px;
    border-radius: 15px;
    border: 2px solid #000;
    animation: throb 1s infinite;
    -webkit-animation: throb 1s infinite;
}
.ss-slide-stage {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
.ss-slide {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform-origin: center;
    transform-origin: center;
    display: block;
    width: 100%;
    zoom: 1;
}
.ss-slide img {
    height: auto;
}
.ss-caption-wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 5px 5px;
    text-align: center;
    box-sizing: border-box;
}
.ss-caption {
    min-height: 50px;
    background: #fff;
    background: rgba(255, 255, 255, 0.87);
    color: #5a5a5a;
    text-shadow: 0 0 10px #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4em;
    border-radius: 3px;
    padding-top: 15px;
    box-sizing: border-box;
    font-family: Veranda, sans-serif;
}
.smoothslides-on a.ss-next,
.smoothslides-on a.ss-prev {
    position: absolute;
    bottom: 5px;
    left: 5px;
    font-size: 14px;
    color: #a1a1a1;
    text-decoration: none;
    background: #fff;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 3px;
    opacity: 0.5;
    transition: 0.2s ease-out;
    font-family: sans-serif;
}
.smoothslides-on a.ss-next {
    left: auto;
    right: 5px;
}
.smoothslides-on:hover .ss-next,
.smoothslides-on:hover .ss-prev {
    opacity: 1;
}
.smoothslides-on .ss-next:hover,
.smoothslides-on .ss-prev:hover {
    background: #fff;
    color: #000;
}
.ss-paginate-wrap {
    position: absolute;
    bottom: -30px;
    width: 100%;
    text-align: center;
}
.ss-paginate {
    display: inline-block;
    line-height: 0;
}
.ss-paginate a:link,
.ss-paginate a:visited {
    display: inline-block;
    width: 16px;
    height: 4px;
    border-radius: 10px;
    margin: 0 5px;
    background: #ccc;
    background: rgba(0, 0, 0, 0.1);
    border: 2px solid #fff;
    transition: 0.3s;
}
.ss-paginate a:hover {
    background: #ddd;
    background: rgba(0, 0, 0, 0.3);
}
.ss-paginate a.ss-paginate-current {
    background: #000;
    background: rgba(0, 0, 0, 0.5);
}
@media (max-width: 600px) {
    .ss-paginate-wrap {
        bottom: -35px;
    }
    .ss-caption {
        min-height: 0;
        padding: 5px;
        font-size: 12px;
    }
    a.ss-next,
    a.ss-prev {
        bottom: auto;
        top: 50%;
        margin-top: -25px;
        background: rgba(255, 255, 255, 0.3);
        color: #444;
    }
    .smoothslides-on,
    .ss-slide-stage {
        border-radius: 0;
    }
}
.social-media {
    font-size: 1.2em;
}
.social-media a:link,
.social-media a:visited {
    width: 1.8em;
    height: 1.8em;
    text-align: center;
    line-height: 1.8em;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
}
.social-media a:hover {
    -webkit-filter: brightness(1.2);
    filter: brightness(1.2);
}
#soc-blogger {
    background: #f49440;
}
#soc-facebook {
    background: #3b5998;
}
#soc-flickr {
    background: #ff1981;
}
#soc-google-plus {
    background: #e04a39;
}
#soc-instagram {
    background: #ba3986;
}
#soc-linkedin {
    background: #0976b4;
}
#soc-paypal {
    background: #00447e;
}
#soc-pinterest {
    background: #bd081c;
}
#soc-soundcloud {
    background: #ff5510;
}
#soc-tumblr {
    background: #36465d;
}
#soc-twitter {
    background: #1da0f2;
}
#soc-vimeo {
    background: #000;
}
#soc-yelp {
    background: #d32323;
}
#soc-youtube {
    background: #ee1c1b;
}
.rwd-embed {
    position: relative;
}
.rwd-embed embed,
.rwd-embed iframe,
.rwd-embed object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.rwd-embed-overlay {
    display: none;
}
.rwd-embed-4-3 {
    width: 100%;
    padding-bottom: 75%;
}
.rwd-embed-16-9 {
    width: 100%;
    padding-bottom: 56.25%;
}
.rwd-embed-21-9 {
    width: 100%;
    padding-bottom: 42.86%;
}
.responsive-iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.responsive-iframe-container embed,
.responsive-iframe-container iframe,
.responsive-iframe-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}
#__bs_notify__ {
    opacity: 0.7;
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    background: rgba(0, 0, 0, 0.81) !important;
    color: #fff !important;
    font-size: 10px !important;
    border-radius: 30px !important;
    font-weight: 700;
    width: 300px !important;
}
